<template>
  <div class="flex column center" style="margin-bottom:4rem;">
    <div class="new_box">
      <div class="titlee">【官宣】“污水验毒”专用智慧采样器，硬核上线啦~</div>
      <p class="time">2020.12.03</p>

      <p class="space_content" style="text-indent:0">
        好消息！
      </p>
      <p class="space_content" style="text-indent:0">
        厦门历思科技服务有限公司 旗下的
      </p>
      <p class="space_content" style="text-indent:0">
        厦门堃铭生物技术有限公司
      </p>
      <p class="space_content" style="text-indent:0">
        又出了一“硬核设备”
      </p>
      <p class="space_content" style="text-indent:0">
        “污水验毒”专用智慧采样器
      </p>
      <p class="space_content" style="text-indent:0">
        ——智慧水质采样器 LS-WS20OF01
      </p>
      <p class="space_content" style="text-indent:0">
        上线啦！
      </p>
      <p class="space_content" style="text-indent:0">
        那么，这个设备“硬核”在哪些方面呢？一起来看看：
      </p>
      <p class="space_content" style="text-indent:0">
        理想的规范化采样解决方案
      </p>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/34-1.png')">
      </div>
      <p class="space_content" style="text-indent:0">
        在“污水验毒”、环境监测、环保督查等工作中，数据的真实性和客观性关系到管理部门对态势情报的真实掌握；也关系到对地方环保、禁毒等工作的评价，过程一旦出现疏漏，就可能造成对基层积极性的挫伤。检验鉴定的取样环节是确保真实、客观还原事实的源点，因此取样应做到标准科学、程序严密、全程受控。
      </p>
      <p class="space_content" style="text-indent:0">
        LS-WS20OF01新一代智慧水质采样器，是当前“污水验毒”、环境监测、环保督查等工作中理想的规范化采样解决方案。
      </p>
      <p class="space_content" style="text-indent:0">
        后续会一一再为大家介绍采样器在环境监测、环保督查等工作中理想的规范化采样解决方案。
      </p>

      <p class="space_content" style="text-indent:0;font-weight: 600">
        0失控
      </p>
      <p class="space_content" style="text-indent:0">
        授权操作/防破坏设计/实时监控与告警/取样瓶唯一性设计/可溯源管理：可最大程度减少人为介入影响，确保公正取样。
      </p>
      <p class="space_content" style="text-indent:0;font-weight: 600">
        0偏离
      </p>
      <p class="space_content" style="text-indent:0">
        污水验毒通常需要在样品冰冻保存条件下进行为期一周的持续采样：目前市面仅有的真正可以满足采样标准的持续采样设备。
      </p>
      <p class="space_content" style="text-indent:0;font-weight: 600">
        0束缚
      </p>
      <p class="space_content" style="text-indent:0">
        不受采样方案限制：时间点、时间间隔、进样量、样品混合方式均可灵活编程组合，7天以上持续采样周期，从而全面适应各种采样方案要求。
      </p>
      <p class="space_content" style="text-indent:0;font-weight: 600">
        0距离
      </p>
      <p class="space_content" style="text-indent:0">
        智慧物联：远程控制及追踪/采样数据云对接；可实现高效的实时、物联网化管理。
      </p>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/34-2.png')">
      </div>

      <p class="space_content" style="text-indent:0">
        智慧水质采样器LS-WS20OF01全面上线，将更好地服务禁毒实战、提高工作效率、满足基层需要，为打击毒品违法犯罪提供更加高效精准的技术支撑。
      </p>
      <p class="space_content" style="text-indent:0">
        了解更多详情，欢迎大家前来咨询，洽谈合作！
      </p>
      <p class="space_content" style="text-indent:0;font-weight: 600">
        联系我们
      </p>
      <p class="space_content" style="text-indent:0;font-weight: 600">
        公司：厦门堃铭生物技术有限公司
      </p>
      <p class="space_content" style="text-indent:0;font-weight: 600">
        地址：厦门市湖里区湖里大道33号七层 【从西侧上】
      </p>
      <p class="space_content" style="text-indent:0;font-weight: 600">
        招商电话：18060951945
      </p>

    </div>
    <div class="flex wrap .col442" style="width:100%;text-align:left;margin-top:1rem">
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex" style="margin-right:10%">
          <p style="white-space:nowrap">上一篇：</p>
          <router-link class="a" :to="'/news/lsnews/33'"><p class="ellipsis1">中国药科大学狄斌教授一行莅临历思就“污水验毒”等技术进行研讨交流！</p>
          </router-link>
        </div>
      </div>
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex">
          <p style="white-space:nowrap">下一篇：</p>
          <router-link class="a" :to="'/news/lsnews/35'"><p class="ellipsis1">全国首例！历思助力技术调查官参审污染环境案件，宣判啦~</p>
          </router-link>

        </div>
      </div>
      <div class="col2 flex  end1 center">
        <router-link class="a" style="color:#fff" :to="'/news/lsnews'">
          <el-button type="primary">返回列表</el-button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: {}
    }
  },
}
</script>
<style>
.redText {
  background: red;
  color: white;
  font-size: 20px;
  font-weight: 600;
}

.titleText {
  font-size: 20px;
  font-weight: 600;
}
</style>
